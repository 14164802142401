import {
  IconEmailFill,
  IconFacebook,
  IconLocationFill,
  IconPhone,
  IconTiktok,
  IconTwitter,
  IconYoutube,
} from 'components/icons';
import { MAIN_DOMAIN, SITE_MAP, TRANSLATION_ENABLE } from 'globalConstants';
import { useToast } from 'hooks';
import { isEqual, map } from 'lodash';
import Link from 'next/link';
import { Fragment, MouseEvent } from 'react';
import GoogleTranslation from '../google-translation';
import ToastStack from '../toast-stack';

const FACEBOOK_DOMAIN = 'https://www.facebook.com/atmnha/';
const YOUTUBE_DOMAIN = 'https://www.youtube.com/channel/UCawniMq-oiML35r6E7aNAFQ';
const TIKTOK_DOMAIN = 'https://www.tiktok.com/@atmnha';
const TWITTER_DOMAIN = 'https://twitter.com/atmnhavietnam';

const Footer = () => {
  const { toasts, addToast, removeToast } = useToast();
  const navigations = [
    {
      name: 'Bán',
      children: [
        {
          name: 'Nhà mặt phố',
          pathname: '/ban-nha-mat-pho',
          incomingStatus: false,
          isExternal: false,
        },
        {
          name: 'Nhà riêng',
          pathname: '/ban-nha-rieng',
          incomingStatus: false,
          isExternal: false,
        },
        {
          name: 'Biệt thự, nhà liền kề',
          pathname: '/ban-biet-thu-nha-lien-ke',
          incomingStatus: false,
          isExternal: false,
        },
        {
          name: 'Cao ốc, văn phòng',
          pathname: '/ban-cao-oc-van-phong',
          incomingStatus: false,
          isExternal: false,
        },
        {
          name: 'Khách sạn',
          pathname: '/ban-khach-san',
          incomingStatus: false,
          isExternal: false,
        },
        {
          name: 'Nhà xưởng, kho, kiot',
          pathname: '/ban-nha-xuong-kho-kiot',
          incomingStatus: false,
          isExternal: false,
        },
        {
          name: 'Đất nền',
          pathname: '/ban-dat-nen',
          incomingStatus: false,
          isExternal: false,
        },
        {
          name: 'Căn hộ chung cư',
          pathname: '/ban-can-ho-chung-cu',
          incomingStatus: false,
          isExternal: false,
        },
        {
          name: 'Shophouse',
          pathname: '/ban-shophouse',
          incomingStatus: false,
          isExternal: false,
        },
        {
          name: 'Penthouse',
          pathname: '/ban-penthouse',
          incomingStatus: false,
          isExternal: false,
        },
        {
          name: 'Condotel',
          pathname: '/ban-condotel',
          incomingStatus: false,
          isExternal: false,
        },
        {
          name: 'Trang trại, khu nghỉ dưỡng',
          pathname: '/ban-trang-trai-khu-nghi-duong',
          incomingStatus: false,
          isExternal: false,
        },
        {
          name: 'Nhà đất khác',
          pathname: '/ban-nha-dat-khac',
          incomingStatus: false,
          isExternal: false,
        },
      ],
    },
    {
      name: 'Cho thuê',
      children: [
        {
          name: 'Nhà mặt phố',
          pathname: '/cho-thue-nha-mat-pho',
          incomingStatus: false,
          isExternal: false,
          extraProps: {},
        },
        {
          name: 'Nhà riêng',
          pathname: '/cho-thue-nha-rieng',
          incomingStatus: false,
          isExternal: false,
          extraProps: {},
        },
        {
          name: 'Biệt thự, nhà liền kề',
          pathname: '/cho-thue-biet-thu-nha-lien-ke',
          incomingStatus: false,
          isExternal: false,
          extraProps: {},
        },
        {
          name: 'Cao ốc, văn phòng',
          pathname: '/cho-thue-cao-oc-van-phong',
          incomingStatus: false,
          isExternal: false,
          extraProps: {},
        },
        {
          name: 'Khách sạn',
          pathname: '/cho-thue-khach-san',
          incomingStatus: false,
          isExternal: false,
          extraProps: {},
        },
        {
          name: 'Nhà xưởng, kho, kiot',
          pathname: '/cho-thue-nha-xuong-kho-kiot',
          incomingStatus: false,
          isExternal: false,
          extraProps: {},
        },
        {
          name: 'Đất nền',
          pathname: '/cho-thue-dat-nen',
          incomingStatus: false,
          isExternal: false,
          extraProps: {},
        },
        {
          name: 'Căn hộ chung cư',
          pathname: '/cho-thue-can-ho-chung-cu',
          incomingStatus: false,
          isExternal: false,
          extraProps: {},
        },
        {
          name: 'Shophouse',
          pathname: '/cho-thue-shophouse',
          incomingStatus: false,
          isExternal: false,
          extraProps: {},
        },
        {
          name: 'Penthouse',
          pathname: '/cho-thue-penthouse',
          incomingStatus: false,
          isExternal: false,
          extraProps: {},
        },
        {
          name: 'Condotel',
          pathname: '/cho-thue-condotel',
          incomingStatus: false,
          isExternal: false,
          extraProps: {},
        },
        {
          name: 'Phòng trọ',
          pathname: '/cho-thue-phong-tro',
          incomingStatus: false,
          isExternal: false,
          extraProps: {},
        },
        {
          name: 'Nhà đất khác',
          pathname: '/cho-thue-nha-dat-khac',
          incomingStatus: false,
          isExternal: false,
          extraProps: {},
        },
      ],
    },
    {
      name: 'Dịch vụ',
      children: [
        {
          name: 'Định giá',
          pathname: `/${SITE_MAP.VALUATION.INDEX}`,
          incomingStatus: false,
          isExternal: false,
        },
        {
          name: 'Khám phá',
          pathname: `/${SITE_MAP.DISCOVERY.INDEX}`,
          incomingStatus: false,
          isExternal: false,
        },
        {
          name: 'TMĐT',
          pathname: `/${SITE_MAP.E_COMMERCE.INDEX}`,
          incomingStatus: false,
          isExternal: true,
        },
        {
          name: 'Mạng xã hội',
          pathname: `${MAIN_DOMAIN}/${SITE_MAP.SOCIAL_NETWORK.INDEX}/${SITE_MAP.SOCIAL_NETWORK.REAL_ESTATE_POST.INDEX}`,
          incomingStatus: false,
          isExternal: true,
        },
        {
          name: 'Tin tức',
          pathname: `/${SITE_MAP.BLOG.INDEX}`,
          incomingStatus: false,
          isExternal: false,
        },
        {
          name: 'Bản đồ quy hoạch',
          pathname: `/${SITE_MAP.MAP.INDEX}?mapType=internal`,
          incomingStatus: false,
          isExternal: false,
        },
        {
          name: 'Công chứng',
          pathname: `${MAIN_DOMAIN}/${SITE_MAP.NOTARIZATION.INDEX}`,
          incomingStatus: false,
          isExternal: true,
        },
        {
          name: 'Vay vốn ngân hàng',
          pathname: `${MAIN_DOMAIN}/${SITE_MAP.BANK_LOAN.INDEX}`,
          incomingStatus: false,
          isExternal: true,
        },
        {
          name: 'Biến động tài sản',
          pathname: `${MAIN_DOMAIN}/${SITE_MAP.OFFICE_REGISTRATION.INDEX}`,
          incomingStatus: false,
          isExternal: true,
        },
        {
          name: 'Cộng đồng môi giới',
          pathname: `${MAIN_DOMAIN}/${SITE_MAP.LIST_BROKER.INDEX}`,
          incomingStatus: false,
          isExternal: false,
        },
      ],
    },
  ];
  const stores = [
    {
      background: 'bg-[url("/logos/google-play.svg")]',
      href: 'https://play.google.com/store/apps/details?id=com.app.realtech247.atmnha.prod',
    },
    {
      background: 'bg-[url("/logos/app-store.svg")]',
      href: 'https://apps.apple.com/vn/app/atm-nh%C3%A0/id6443934765?l=vi',
    },
  ];
  const socials = [
    {
      icon: <IconFacebook className='h-[18px] w-[18px]' />,
      href: FACEBOOK_DOMAIN,
    },
    {
      icon: <IconYoutube className='h-[18px] w-[18px]' />,
      href: YOUTUBE_DOMAIN,
    },
    {
      icon: <IconTiktok className='h-[18px] w-[18px]' />,
      href: TIKTOK_DOMAIN,
    },
    {
      icon: <IconTwitter className='h-[18px] w-[18px]' />,
      href: TWITTER_DOMAIN,
    },
  ];
  const stack = [
    {
      label: 'Giới thiệu',
      href: `${MAIN_DOMAIN}/${SITE_MAP.GENERAL_INFORMATION.INDEX}#gioi-thieu`,
    },
    {
      label: 'Các dịch vụ chính',
      href: `${MAIN_DOMAIN}/${SITE_MAP.GENERAL_INFORMATION.INDEX}#cac-dich-vu-chinh`,
    },
    {
      label: 'Liên hệ',
      href: `${MAIN_DOMAIN}/${SITE_MAP.GENERAL_INFORMATION.INDEX}#lien-he`,
    },
    {
      label: 'Báo giá và hỗ trợ',
      href: `${MAIN_DOMAIN}/${SITE_MAP.GENERAL_INFORMATION.INDEX}#bao-gia-va-ho-tro`,
    },
    {
      label: 'Những câu hỏi thường gặp',
      href: `${MAIN_DOMAIN}/${SITE_MAP.GENERAL_INFORMATION.INDEX}#nhung-cau-hoi-thuong-gap`,
    },
    {
      label: 'Điều khoản thoả thuận',
      href: `${MAIN_DOMAIN}/${SITE_MAP.GENERAL_INFORMATION.INDEX}#dieu-khoan-thoa-thuan`,
    },
    {
      label: 'Trách nhiệm và nghĩa vụ của người sử dụng',
      href: `${MAIN_DOMAIN}/${SITE_MAP.GENERAL_INFORMATION.INDEX}#trach-nhiem-va-nghia-vu-cua-nguoi-su-dung`,
    },
    {
      label: 'Sở hữu trí tuệ',
      href: `${MAIN_DOMAIN}/${SITE_MAP.GENERAL_INFORMATION.INDEX}#so-huu-tri-tue`,
    },
    {
      label: 'Chính sách bảo mật',
      href: `${MAIN_DOMAIN}/${SITE_MAP.GENERAL_INFORMATION.INDEX}#chinh-sach-bao-mat`,
    },
    {
      label: 'Xác nhập hoặc nhượng quyền',
      href: `${MAIN_DOMAIN}/${SITE_MAP.GENERAL_INFORMATION.INDEX}#nhuong-quyen`,
    },
    {
      label: 'Cơ chế giải quyết khiếu nại',
      href: `${MAIN_DOMAIN}/${SITE_MAP.GENERAL_INFORMATION.INDEX}#co-che-giai-quyet-khieu-nai`,
    },
    {
      label: 'Quy định khi đăng tin và quảng cáo',
      href: `${MAIN_DOMAIN}/${SITE_MAP.GENERAL_INFORMATION.INDEX}#quy-dinh-khi-dang-tin-va-quang-cao`,
    },
  ];

  const clickIncomingFeature = (event: MouseEvent<HTMLAnchorElement>, incomingStatus?: boolean) => {
    if (incomingStatus) {
      event.preventDefault();
      addToast('information', 'Chức năng đang được hoàn thiện!');
    }
  };

  return (
    <Fragment>
      <footer
        id='footer'
        className='min-w-[1052px] bg-[url("/images/footer-background.webp")] bg-cover bg-center bg-no-repeat text-paper'
      >
        <div className='bg-primary opacity-90'>
          <div className='container mx-auto min-w-[1052px] px-[48px] pt-[46px] pb-[16px]'>
            <div className='flex justify-between space-x-[60px] xl:space-x-[20px]'>
              <div className='flex flex-col space-y-[20px] xl:w-1/4'>
                <img
                  src='/logos/atm-nha-white.svg'
                  alt='ATM Nhà'
                  loading='lazy'
                  className='h-[40px] w-[132px]'
                />
                <div className='flex items-center space-x-[8px]'>
                  <IconPhone className='min-h-[24px] min-w-[24px]' />
                  <div className='flex flex-col'>
                    <span className='text-[13px] leading-[16px]'>Điện thoại</span>
                    <span className='text-[13px] font-[600] leading-[16px]'>0789 333 322</span>
                  </div>
                </div>
                <div className='flex items-center space-x-[8px]'>
                  <IconEmailFill className='min-h-[24px] min-w-[24px]' />
                  <div className='flex flex-col'>
                    <span className='text-[13px] leading-[16px]'>Email</span>
                    <span className='text-[13px] font-[600] leading-[16px]'>contact@atmnha.vn</span>
                  </div>
                </div>
                <div className='flex items-center space-x-[8px]'>
                  <IconLocationFill className='min-h-[24px] min-w-[24px]' />
                  <div className='flex flex-col'>
                    <span className='text-[13px] leading-[16px]'>Địa chỉ</span>
                    <span className='text-[13px] font-[600] leading-[16px]'>
                      18 Trần Thiện Chánh, P.12, Q.10, Tp. Hồ Chính Minh
                    </span>
                  </div>
                </div>
                <div className='h-[45px] w-[119px] bg-[url("/logos/ministry-of-industry-and-trade.svg")] bg-cover bg-center bg-no-repeat' />
              </div>
              <div className='flex space-x-[36px]'>
                {map(navigations, (navigation, navigationIndex) => (
                  <div key={navigationIndex} className='flex flex-col space-y-[8px]'>
                    <span className='text-[16px] font-[800] uppercase leading-[20px]'>
                      {navigation.name}
                    </span>
                    <div className='text-[13px] leading-[28px]'>
                      {map(navigation.children, (navigationChild, navigationChildIndex) => (
                        <Fragment key={navigationChildIndex}>
                          <Link
                            href={navigationChild.pathname}
                            {...(navigationChild.isExternal
                              ? { target: '_blank', rel: 'noopener noreferrer' }
                              : {})}
                            onClick={(event) =>
                              clickIncomingFeature(event, navigationChild.incomingStatus)
                            }
                          >
                            <span>{navigationChild.name}</span>
                          </Link>
                          <br />
                        </Fragment>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
              <div className='flex flex-col space-y-[44px]'>
                {isEqual(TRANSLATION_ENABLE, 'true') && (
                  <Fragment>
                    <div className='flex flex-col space-y-[12px]'>
                      <span className='text-[16px] font-[800] uppercase leading-[20px]'>
                        ngôn ngữ
                      </span>
                      <GoogleTranslation />
                    </div>
                  </Fragment>
                )}
                <div className='flex flex-col space-y-[12px]'>
                  <span className='text-[16px] font-[800] uppercase leading-[20px]'>
                    Tải ứng dụng trên điện thoại
                  </span>
                  <div className='flex items-center space-x-[16px]'>
                    {map(stores, (store, storeIndex) => (
                      <Link
                        key={storeIndex}
                        href={store.href}
                        passHref
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        <div
                          className={`h-[32px] w-[120px] bg-cover bg-center bg-no-repeat ${store.background}`}
                        />
                      </Link>
                    ))}
                  </div>
                </div>
                <div className='flex flex-col space-y-[12px]'>
                  <span className='text-[16px] font-[800] uppercase leading-[20px]'>
                    Kết nối với chúng tôi
                  </span>
                  <div className='flex items-center space-x-[16px]'>
                    {map(socials, (social, socialIndex) => (
                      <Link
                        key={socialIndex}
                        href={social.href}
                        passHref
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        <div className='flex h-[32px] w-[32px] items-center justify-center rounded-full bg-[#000000]/[.2]'>
                          {social.icon}
                        </div>
                      </Link>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className='mx-[60px] mt-[41px] h-[1px] bg-paper' />
            <div className='flex flex-col items-center'>
              <div className='mt-[13px] flex flex-wrap items-center justify-center px-[140px] xl:px-[60px]'>
                {map(stack, (stackItem, stackItemIndex) => (
                  <Fragment key={stackItemIndex}>
                    {!isEqual(stackItemIndex, 0) && (
                      <div className='mx-[20px] h-[12px] w-[1px] bg-paper' />
                    )}
                    <Link href={stackItem.href} passHref target='_blank' rel='noopener noreferrer'>
                      <div className='flex h-[32px] items-center text-[13px] font-[600]'>
                        {stackItem.label}
                      </div>
                    </Link>
                  </Fragment>
                ))}
              </div>
              <p className='mt-[16px] px-[120px] text-center text-[12px] leading-[20px] xl:px-[40px]'>
                Giấy ĐKKD số: 0316747912 Do Sở Kế hoạch và Đầu tư Thành phố Hồ Chí Minh cấp lần đầu
                ngày 15/03/2021.
                <br />
                Chịu trách nhiệm nội dung: Ông Võ Văn Tính - ® Ghi rõ nguồn &quot;atmnha.vn&quot;
                khi phát hành lại thông tin từ website này.
                <br />
                Toàn bộ quy chế, quy định giao dịch chung được đăng tải trên website áp dụng từ ngày
                15/03/2021.
              </p>
            </div>
            <p className='mt-[16px] px-[120px] text-center text-[12px] leading-[20px] xl:px-[40px]'>
              CÔNG TY CỔ PHẦN CÔNG NGHỆ KỲ LÂN ZMS <br />
              Giấy ĐKKD số: 0316747912 Do Sở Kế hoạch và Đầu tư Thành phố Hồ Chí Minh cấp lần đầu
              ngày 15/03/2021.
              <br />
              Chịu trách nhiệm nội dung: Ông Võ Văn Tính - ® Ghi rõ nguồn &quot;atmnha.vn&quot; khi
              phát hành lại thông tin từ website này.
              <br />
              Toàn bộ quy chế, quy định giao dịch chung được đăng tải trên website áp dụng từ ngày
              15/03/2021.
            </p>
          </div>
        </div>
      </footer>
      <ToastStack toasts={toasts} removeToast={removeToast} />
    </Fragment>
  );
};

export default Footer;
